import React from 'react'

type Props = {}

const Header = (props: Props) => {
    return (
        <header className='relative flex justify-center items-center h-[100vh] overflow-hidden'>
            <video loop muted autoPlay className='absolute w-full h-full top-1/2 left-1/2 -z-[1] object-cover transform -translate-x-1/2 -translate-y-1/2 '>
                <source src='assets/Flag.mp4'></source>
            </video>
            <div className='bg-[#00008b80] w-full h-full absolute top-0 left-0 z-0'></div>
            <div className='z-[1] p-5 relative flex flex-col items-center'>
                <img src="assets/logo.png" className='size-[250px] mb-3 rounded-full' alt="" />
                <h1 className='text-[#fff] py-5 text-2xl md:text-4xl font-bold'>Harris and Walz</h1>
                <div className='flex justify-between items-center gap-5'>
                    <a href="https://dexscreener.com/solana/aqjtidpxxmjcjydeps3aznqqfa8tkoanguxu6camvqni" target="_blank" rel="noreferrer">
                        <img src="/assets/dexscreener-icon.png" className='size-[30px] md:size-[40px]' alt="" />
                    </a>
                    <a href="https://x.com/HNWSOL" target="_blank" rel="noreferrer">
                        <img src="/assets/twitter-icon.png" className='size-[30px] md:size-[40px]' alt="" />
                    </a>
                    <a href="https://t.me/HarrisandWalz" target="_blank" rel="noreferrer">
                        <img src="/assets/telegram-icon.png" className='size-[30px] md:size-[40px]' alt="" />
                    </a>
                </div>
            </div>
        </header>
    )
}

export default Header