import React from 'react'

type Props = {}

const Tokenomic = (props: Props) => {
    return (
        <div className='w-full flex justify-between items-center gap-8'>
            <img src="assets/hnw-tachnen-2.gif" className='w-[150px] hidden md:block' alt="" />
            <div className='w-full bg-[#ff0000] rounded-xl text-[#fff] text-center p-5 flex flex-col items-center gap-5'>
                <img src="assets/hnw-tachnen-2.gif" className='w-[150px] block md:hidden' alt="" />
                <h2 className='mb-2 text-2xl md:text-4xl font-bold break-words'>$HNW Tokenomics</h2>
                <p className='break-words text-sm md:text-base'><span className='font-bold'>CA:</span> 8GbqfueNr9uxA9DKYzE4aAaWtRbmEMxfnm24RfnaVzb8 </p>
                <p className='break-words text-sm md:text-base'><span className='font-bold'>0/0 TAX:</span> Enjoy trading with zero taxes on transactions.</p>
                <p className='break-words text-sm md:text-base'><span className='font-bold'>LP BURNED:</span> Liquidity Pool is permanently burned, ensuring stability and security.</p>
                <p className='break-words text-sm md:text-base'><span className='font-bold'>Total Supply:</span> 1 billion tokens.</p>
                <img src="assets/hnw-tachnen-2.gif" className='w-[150px] block md:hidden' alt="" />
            </div>
            <img src="assets/hnw-tachnen-2.gif" className='w-[150px] hidden md:block' alt="" />
        </div>
    )
}

export default Tokenomic