import React from 'react'

type Props = {}

const HowtoBuy = (props: Props) => {
    return (
        <div className='w-full flex justify-between items-center gap-8'>
            <img src="assets/hnw-tachnen-3.gif" className='w-[150px] hidden md:block' alt="" />
            <div className='w-full bg-white rounded-xl text-black text-center p-5 flex flex-col items-center gap-5'>
                <img src="assets/hnw-tachnen-3.gif" className='w-[150px] block md:hidden' alt="" />
                <h2 className='mt-5 mb-2 text-2xl md:text-4xl font-bold'>How to Buy $HNW</h2>
                <div className='flex'>
                    <strong className='w-[5%] flex justify-start items-start'>1</strong>
                    <p className='text-sm md:text-base  w-[95%]'>
                        Download Phantom Wallet from app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app
                    </p>
                </div>
                <hr className='border border-[#000] w-full' />
                <div className='flex'>
                    <strong className='w-[5%] flex justify-start items-start'>2</strong>
                    <p className='text-sm md:text-base  w-[95%]'>
                        Have SOL in your wallet to switch to $TIMWALZ. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.
                    </p>
                </div>
                <hr className='border border-[#000] w-full' />
                <div className='flex'>
                    <strong className='w-[5%] flex justify-start items-start'>3</strong>
                    <p className='text-sm md:text-base w-[95%]'>
                        {/* 0x155788dd4b3ccd955a5b2d461c7d6504f83f71fa */}
                        Connect to Raydium. Go to raydium.io/swap in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $TIMWALZ token address into Raydium, select TIMWALZ, and confirm. When Phantom prompts you for a wallet signature, sign.
                    </p>
                </div>
                <hr className='border border-[#000] w-full' />
                <div className='flex'>
                    <strong className='w-[5%] flex justify-start items-start'>4</strong>
                    <p className='text-sm md:text-base w-[95%]'>
                        Switch SOL for $HNW. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.
                    </p>
                </div>
                <img src="assets/hnw-tachnen-3.gif" className='w-[150px] block md:hidden' alt="" />
            </div>
            <img src="assets/hnw-tachnen-3.gif" className='w-[150px] hidden md:block' alt="" />
        </div>
    )
}

export default HowtoBuy