import React from 'react';

type Props = {};

const Meme = (props: Props) => {
    const data = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <div className='w-full flex flex-col md:flex-row justify-between items-center gap-8 p-4'>
            <img src="assets/hnw-tachnen-2.gif" className='w-[150px] md:w-[200px] mb-4 md:mb-0 hidden md:block mt-14' alt="" />
            <div className='w-full p-5 bg-[#00008b] rounded-lg flex flex-col items-center'>
                <img src="assets/hnw-tachnen-2.gif" className='w-[150px] md:w-[200px] block md:hidden mb-5' alt="" />
                <div className='w-full text-center mb-4'>
                    <h2 className='text-3xl md:text-4xl text-white font-bold'>Meme Gallery</h2>
                </div>
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    {
                        data.map(item => (
                            <div key={item} className='flex justify-center'>
                                <img src={`/assets/HNW-${item}.gif`} className='w-full h-auto rounded-lg' alt='' />
                            </div>
                        ))
                    }
                </div>
                <img src="assets/hnw-tachnen-2.gif" className='w-[150px] md:w-[200px] mb-4 md:mb-0 block md:hidden mt-10' alt="" />
            </div>
            <img src="assets/hnw-tachnen-2.gif" className='w-[150px] md:w-[200px] mb-4 md:mb-0 hidden md:block mt-14' alt="" />
        </div>
    );
};

export default Meme;
