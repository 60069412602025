import React from 'react'

type Props = {}

const About = (props: Props) => {
    return (
        <div className='w-full flex justify-between items-center gap-8'>
            <img src="assets/hnw-tachnen-1.gif" className='w-[150px] hidden md:block' alt="" />
            <div className='w-full bg-[#00008b] rounded-xl text-[#fff] text-center flex flex-col items-center p-5'>
                <img src="assets/hnw-tachnen-1.gif" className='w-[150px] block md:hidden' alt="" />
                <h2 className='my-5 text-2xl md:text-4xl font-bold '>About Harris and Walz </h2>
                <p className='text-sm md:text-base'>Make Non-American Great Again!</p>
                <img src="assets/hnw-tachnen-1.gif" className='w-[150px] block md:hidden mt-10' alt="" />
            </div>
            <img src="assets/hnw-tachnen-1.gif" className='w-[150px] hidden md:block' alt="" />
        </div>
    )
}

export default About