import React from 'react'
import Tokenomic from './Tokenomic'
import About from './About'
import HowtoBuy from './Howtobuy'
import Meme from './Meme'

type Props = {}

const Content = (props: Props) => {
    return (
        <main className='bg-[#111] p-5 w-full'>
            <div className='containerCustom text-white flex flex-col gap-10'>
                <About />
                <Tokenomic />
                <HowtoBuy />
                <Meme />
            </div>
        </main>
    )
}

export default Content