import React from 'react'

type Props = {}

const Footer = (props: Props) => {
    return (
        <div className='w-full flex flex-col justify-center items-center p-5 bg-[#222] text-center'>
            <div className='max-w-[600px] bg-[#333] p-3 text-white text-xs text-center rounded-[10px]'>
                Harris and Walz Token is a cryptocurrency created for entertainment purposes and is not affiliated with or endorsed by Kamala Harris. and Tim Walz While Harris and Walz Token may reference or incorporate elements related Kamala Harris and Tim Walz´ persona, image, or likeness, it does not imply any direct endorsement, partnership, or approval by Kamala Harris and Tim Walz. Any resemblance or association between Harris and Walz Token and Kamala Harris and Tim Walz is purely coincidental and intended for satirical or humorous purposes.
            </div>
        </div>
    )
}

export default Footer